import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import "../components/CSS/form.css"
import * as L from '../components/Solution/styled'
import * as S from '../components/Content/styled';
import { LocaleContext } from '../components/Layout';

const Faqs = props => {
  const post = props.data.markdownRemark;
  const { locale } = React.useContext(LocaleContext);
  return (
    <>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
      />
      <S.Content>
        <h1>{post.frontmatter.title}</h1>
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
      </S.Content>
    </>
  );
};

export const query = graphql`
  query Faqs($locale: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        description
        page
     
      }
      html
    }
  }
`;

export default Faqs;
